@use 'sass:map';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/spacing' as *;

.root {
  text-align: left;

  &:not([class*='transparent']) {
    border-radius: map.get($radiuses, '4');
    border-style: solid;
    border-width: 0 0 0 map.get($borders, '2');
    padding: map.get($spacers, 'spacing', '4');

    @include breakpoint('lg', 'min') {
      padding: map.get($spacers, 'spacing', '5');
    }
  }
}

@each $background, $border in ('blue-moon-tint': 'blue-moon', 'lily-of-the-valley': 'charcoal-charlie', 'purple-rain-tint': 'purple-rain', 'transparent' : 'transparent', 'white': 'charcoal-charlie', 'yellow-submarine-tint': 'yellow-submarine') {
  .#{$background} {
    background-color: map.get($colors, $background);
    border-left-color: map.get($colors, $border);

    [class*='#{$background}']:not([class*='transparent']) & {
      border-color: map.get($colors, 'blackbird-500') map.get($colors, 'blackbird-500') map.get($colors, 'blackbird-500') map.get($colors, $border);
      border-width: map.get($borders, '1') map.get($borders, '1') map.get($borders, '1') map.get($borders, '2');
    }

    .icon {
      background-color: map.get($colors, 'white');
    }
  }
}

@each $border, $background in ('blue-moon': 'blue-moon-tint', 'charcoal-charlie': 'lily-of-the-valley', 'purple-rain': 'purple-rain-tint', 'yellow-submarine': 'yellow-submarine-tint') {
  .#{$border} {
    background-color: map.get($colors, 'white');
    border-left-color: map.get($colors, $border);

    [class*='white'] & {
      border-color: map.get($colors, 'blackbird-500') map.get($colors, 'blackbird-500') map.get($colors, 'blackbird-500') map.get($colors, $border);
      border-width: map.get($borders, '1') map.get($borders, '1') map.get($borders, '1') map.get($borders, '2');
    }

    .icon {
      background-color: map.get($colors, $background);
    }
  }
}

.stretch {
  min-height: 100%;
}

.editor {
  @include spacing('md', 'bottom', 'margin');
}

.icon {
  align-items: center;
  border-radius: map.get($radiuses, '3');
  display: flex;
  height: 4rem;
  justify-content: center;
  width: 4rem;

  @include spacing('md', 'bottom', 'margin');

  img {
    max-height: 2.75rem;
    max-width: 2.75rem;
    object-fit: contain;
  }
}

.note {
  @include spacing('md', 'bottom', 'margin');
}

.title {
  @include spacing('md', 'bottom', 'margin');
}
